import Uri from 'urijs'

const PRODUCTION_HOSTNAMES = ['h.ai', 'www.h.ai']
const API_URL = PRODUCTION_HOSTNAMES.includes(window.location.hostname) ?
 'https://api.h.ai'
 : 'https://stagingapi.h.ai'


const getCsrfCookie = () => {
  let name = 'csrftoken'
  let cookies = document.cookie
  cookies = cookies.split(';')
  for (var i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim()
    if (cookie.substr(0, 9) === name) {
      return cookie.substr(10)
    }
  }
  return null
}


export const getToken = () => new Promise((resolve, reject) => {
  // add utm data and referrer for analytics purpose
  let query = new Uri().query(true)
  let url = new Uri(`${API_URL}/mailchimp/`).addQuery(query).addQuery(
    'externalReferrer', document.referrer)

  return fetch(url,
    {
      method: 'GET',
      credentials: 'include'
    }
  )
  .then(resp => resolve(resp))
  .catch(err => reject(err))
})


export const signUp = ({ email, linkOnly }) => new Promise((resolve, reject) => {
  const query = new Uri().query(true)
  const externalReferrer = document.referrer
  const data = {
    ...query, // pass utm query parameters
    externalReferrer, // pass the current referrer as externalReferrer
    email,
    linkOnly,
  }
  const headers = new Headers()
  headers.append('X-CSRFToken', getCsrfCookie())
  headers.append('Content-Type', 'application/json')
  return fetch(`${API_URL}/mailchimp/`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(data)
    }
  )
  .then(resp => {
    if (resp.status === 200) return resolve(resp)
    resp.json().then(data => reject(data.error || 'Cannot sign up now, maybe retry later'))
  })
  .catch(err => reject(err))
})
