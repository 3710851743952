import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { media, Row, Column, H1, P } from "../../theme";
import PressMediaNav from "../../components/PressMediaNav";
import Data from "./data";

const Section = styled(Row)`
  margin-bottom: 100px;
`;
const Article = styled(Column)`
  margin-bottom: 40px;
  & a {
    text-decoration: none;
  }
  ${media.md`
    width: calc(50% - 32px);
  `}
  ${media.lg`
    width: calc(33.3333% - 32px);
  `}
`;

const Thumb = styled.img`
  width: 100%;
  height: 210px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const ArticleTitle = styled(H1)`
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding: 10px 0;
`;

const Paragraph = styled(P)`
  font-weight: 400;
`;

const Type = styled.span`
  line-height: 18px;
  font-size: 14px;
  color: #4b3731;
  text-transform: capitalize;
`;

const Date = styled.span`
  line-height: 18px;
  font-size: 14px;
  color: #4b3731;
`;

const PressPage = () => (
  <Section>
    <Helmet>
      <title>{Data.seo}</title>
      <meta name="description" content="Helmet application" />
    </Helmet>
    <PressMediaNav />
    {Data.articles.map(item => (
      <Article key={item.id}>
        <a href={item.href} rel="noopener noreferrer" target="_blank">
          <Thumb src={item.src} srcSet={item.srcSet} />
          <Type>{item.type}</Type>
          <ArticleTitle>{item.title}</ArticleTitle>
          <Paragraph>{item.text}</Paragraph>
          <Date>{item.date}</Date>
        </a>
      </Article>
    ))}
  </Section>
);

export default PressPage;
