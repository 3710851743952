import styled from "styled-components";
import { media } from "../theme";

const BlogArticle = styled.article`
  width: 100%;
  max-width: 768px;
  margin: auto;
  padding: 0 30px;
  ${media.md`
    padding: 0 65px;
  `}
  & figure {
    width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 25px;
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & blockquote {
    width: 100%;
    letter-spacing: 0;
  }
  & h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 16px;
  }
  & h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 8px;
  }
  & h3, blockquote {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 8px;
  }
  & h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    padding-bottom: 4px;
  }
  & p {
    font-weight: 400;
    letter-spacing: 0.01em;
    margin-bottom: 25px;
  }
  & blockquote {
    font-style: italic;
    padding: 24px 48px 56px 48px;
    ${media.sm`
      padding: 40px;
    `}
    ${media.xs`
      padding: 15px;
    `}
  }
  & figcaption {
    font-size: 14px;
    line-height: 16px;
  }
`;

export default BlogArticle;
