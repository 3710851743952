import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { media } from "../theme";
import { GetApp } from "../pages";
import HaiLogo from "../assets/Hai_Logo.svg";

const HeaderWrap = styled.header`
  position: fixed;
  height: 63px;
  width: 100%;
  padding: 25px 30px 10px 30px;
  overflow: visible;
  z-index: 999;
  transition: all ease-out 0.5s;
  &.show {
    background: rgba(255, 255, 255, 0.95);
  }
  ${media.md`
    height: 80px;
    padding: 20px 65px 20px 65px;
  `};
  ${media.xl`
    padding: 20px 256px 20px 256px;
  `}
`;

const Logo = styled(NavLink)`
  position: absolute;
  height: 28px;
  & img {
    height: 100%;
  }
  ${media.md`
    height: 40px;
  `}
`;

const Nav = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100vw - 25px);
  height: 100vh;
  top: 0;
  padding: 65px;
  background: #2d1943;
  box-shadow: 0 0 0 30px rgba(37, 38, 61, 0.85);
  transform: translateX(0);
  transition: transform ease-in-out 0.25s;
  /* Hide menu */
  &.hide {
    transform: translateX(100vw);
    /* transform: translateX(0); */
  }
  &.show + div > span,
  &.show + div > span:before,
  &.show + div > span:after {
    background: #fff;
  }
  &.show + div > span {
    background: rgba(255, 255, 255, 0);
  }
  &.show + div > span:before {
    transform: rotate(-135deg);
    top: 0;
  }
  &.show + div > span:after {
    transform: rotate(135deg);
    bottom: 0;
  }

  ${media.md`
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
    top: 28px;
    right: 65px;
    padding: 0;
    width: calc(100vw - 205px);
    height: 32px;
    background: none;
    box-shadow: none;
    & button {
      display: block;
    }
    &.hide {
      transform: translateX(0);
    }
  `}
  ${media.xl`
    width: calc(100vw - 585px);
    right: 256px;
  `}
`;

const Link = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  line-height: 32px;
  font-size: 22px;
  color: #fff;
  margin: 0 0 40px 0;
  &:hover,
  &.active {
    color: #ff6a61;
  }
  ${media.md`
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #2D1943;
    margin: 0 30px 0 0;
  `}
`;

const Burger = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 30px;
  margin: 6px 0;
  z-index: 9999;
  cursor: pointer;
  & span,
  & span:before,
  & span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background: #2d1943;
    border-radius: 2px;
    transition: all 0.25s;
  }
  & span {
    z-index: 9999;
    top: calc(50% - 1px);
  }
  & span:before {
    top: -6px;
  }
  & span:after {
    bottom: -6px;
  }

  ${media.md`
    display: none;
  `}
`;

const Button = styled.span`
  display: none;
  ${media.md`
    display: block;
    height: auto;
    padding: 0 16px;
    text-decoration: none;
    line-height: 32px;
    font-size: 0.875em;
    text-align: center;
    color: #2d1943;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.01em;
    border-radius: 4px;
    border: none;
    background: #ff6a61;
    cursor: pointer;
  `}
`;

const PopupWrap = styled.div`
  /* display: none; */
  position: fixed;
  &.show {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in;
  }
  &.hide {
    visibility: hidden;
    opacity: 0;
    /* visibility: visible;
    opacity: 1; */
    transition: visibility 0s 0.2s, all 0.2s ease-out;
  }
  &,
  & > .bglayer {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
  & > .bglayer {
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
  }
  & > .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 60px;
    right: 90px;
    margin: 6px 0;
    z-index: 9999;
    cursor: pointer;
    & span,
    & span:before,
    & span:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      background: #2d1943;
      border-radius: 2px;
      transition: all 0.25s;
    }
    & span {
      z-index: 9999;
      top: calc(50% - 1px);
      background: rgba(0, 0, 0, 0);
    }
    & span:before {
      transform: rotate(-135deg);
      top: 0;
    }
    & span:after {
      transform: rotate(135deg);
      bottom: 0;
    }
  }
`;

class Header extends React.Component {
  state = {
    isMobile: true,
    isPopup: false,
    isTop: true
  };

  toggle = this.toggle.bind(this);
  togglePop = this.togglePop.bind(this);
  hide = this.hide.bind(this);
  onScroll = this.onScroll.bind(this);

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 70;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }
  hide() {
    this.setState({
      isMobile: true,
      isPopup: false
    });
  }

  toggle() {
    this.setState({
      isMobile: !this.state.isMobile
    });
  }
  togglePop() {
    this.setState({
      isPopup: !this.state.isPopup
    });
  }

  render() {
    return (
      <HeaderWrap className={this.state.isTop ? "hide" : "show"}>
        <Logo exact to="/">
          <img src={HaiLogo} alt="hai logo" />
        </Logo>
        <Nav className={this.state.isMobile ? "hide" : "show"}>
          <Link exact to="/" onClick={this.hide}>
            Home
          </Link>
          <Link to="/about" onClick={this.hide}>
            About
          </Link>
          <Link to="/press-and-media" onClick={this.hide}>
            Press & Media
          </Link>
          <Link to="/blog" onClick={this.hide}>
            Blog
          </Link>
          <Button onClick={this.togglePop}>Try Hai</Button>
        </Nav>
        <Burger onClick={this.toggle}>
          <span />
        </Burger>
        <PopupWrap className={!this.state.isPopup ? "hide" : "show"}>
          <div className="close" onClick={this.hide}>
            <span />
          </div>
          <GetApp />
          <div className="bglayer" onClick={this.hide} />
        </PopupWrap>
      </HeaderWrap>
    );
  }
}
export default Header;
