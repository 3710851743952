import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { LeftRow, Column, media, H1, P } from "theme";
import { staticSrc, staticSrcSet } from "assets";
import Facebook from "assets/facebook_rc.svg";
import Twitter from "assets/twitter_rc.svg";

const Article = styled(LeftRow)`
  margin-bottom: 120px;
  ${media.md`
    margin-bottom:120px;
  `}
`;
const Info = styled(Column)`
  padding: 0 30px;
  order: 2;
  ${media.md`
    width: 50%;
  `}
`;
const Title = styled(H1)`
  padding: 30px 0 50px 0;
`;

const Paragraph = styled(P)``;

const Figure = styled(Column)`
  order: 1;
  ${media.sm`
    order: 3;
  `}
  text-align: left;
  width: auto;
  max-width: 450px;
  margin-right: auto;
  & img {
    width: 400px;
    height: auto;
    ${media.sm`
      width: 60%;
      margin-right: auto;
    `}
  }
`;

const ShareOn = styled.div`
  text-align: center;
  margin-bottom: 80px;
  & h3 {
    line-height: 22px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4b3731;
    margin: 20px 0 55px 0;
  }
  & a {
    margin-right: 24px;
  }
  & a:last-child {
    margin-right: 0;
  }
`;

const url_encoded_url = 'https%3A%2F%2Fh.ai'
const twitter_msg = 'Sick of endless options? Enjoy customized recommendations for restaurants, TV, movies, books, music, and games. Be the first to try %23Hai. Sign up %F0%9F%91%89'
const twitter_sharer_url = `https://twitter.com/intent/tweet?text=${twitter_msg}&url=${url_encoded_url}`
const fb_sharer_url = `https://www.facebook.com/sharer/sharer.php?u=${url_encoded_url}`

const handleSharerClick = url => event => {
  event.preventDefault()
  window.open(
    url,
    "pop",
    "width=600, height=400, scrollbars=no")
}

const Share = () => (
  <Article>
    <Info max="629px">
      <Title>Don’t forget, sharing is caring. </Title>
      <Paragraph>
        Let the world know the kinds of things you love as you enjoy them. You can use bookmarks and create lists to keep track of ratings for new and old favorites. You can also share your own unique tastes to friends and your new Hai network. For the first time ever, it's ok to be a critic. In fact, it's very much welcomed and appreciated.
      </Paragraph>
      <ShareOn>
        <h3>Who wouldn't love Hai?<br/> Spread the love by sharing Hai on:</h3>
        <span>
          <a
            href={fb_sharer_url}
            onClick={handleSharerClick(fb_sharer_url)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Facebook} alt="facebook" />
          </a>
          <a
            href={twitter_sharer_url}
            onClick={handleSharerClick(twitter_sharer_url)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Twitter} alt="twitter" />
          </a>
        </span>
      </ShareOn>
    </Info>
    <Figure>
      <Fade left>
        <img
          src={staticSrc('macbook/MacBook_2', 'png')}
          srcSet={staticSrcSet('macbook/MacBook_2', 'png')}
          alt="Hai App on MacBook"
        />
      </Fade>
    </Figure>
  </Article>
);

export default Share;
