import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { RightRow, Column, media, H1, P } from "theme";
import AvatarImage from "components/Avatar";
import { staticSrc } from "assets";
import iPhoneDiscover from "assets/iPhoneX-Discover_AskHai.png";

const Article = styled(RightRow)`
  margin-bottom: 120px;
  background: url("${staticSrc('glow/Glow-discover_AskHai', 'svg')}")
    bottom left / 150% no-repeat;
  ${media.md`
    margin-bottom: 120px;
    background: url("${staticSrc('glow/Glow-discover_AskHai', 'svg')}") bottom left /contain no-repeat;
  `}
`;
const Info = styled(Column)`
  padding-right: 30px;
  ${media.md`
    width: 50%;
    order: 2;
  `}
`;
const Title = styled(H1)`
  padding: 30px 0 50px 0;
`;

const Paragraph = styled(P)``;

const Figure = styled(Column)`
  width: 100%;
  max-width: 270px;
  margin-right: auto;
  ${media.md`
    width: auto;
    order: 1;
  `}
  ${media.sm`
    & img {
      width: 60%;
      margin-right: auto;
    }
  `}
`;
const Avatar = styled(AvatarImage)`
  width: 85px;
  &.avatar {
    align-self: center;
  }
  ${media.md`
    &.avatar {
      margin: 15px 0 0 0;
    }
  `}
`;

const Discover = () => (
  <Article>
    <Info max="629px">
      <Avatar className="avatar" />
      <Title>Knows what you want before you do.</Title>
      <Paragraph>
      Your Hai app conveniently serves up new movie, music, book, TV, video game, and restaurant recommendations in seconds—customized recommendations that you can enjoy on Netflix, Hulu, Spotify—anywhere and everywhere.
      An exciting world of new things to enjoy and love is about to open up all around you. Your lifestyle is about to get an upgrade.
      </Paragraph>
    </Info>
    <Figure>
      <Fade left>
        <img src={iPhoneDiscover} alt="Discover" />
      </Fade>
    </Figure>
  </Article>
);

export default Discover;
