import React, { Component } from "react";
import styled from "styled-components";
import { SignedUpContext } from 'context.js'
// import { media } from "../theme";

const FormContainer = styled.div`
  margin: 0 0 25px 0;
`

const Form = styled.form`
  display: flex;
  flex-flow: row;
  height: 40px;
  height: 40px;
  & > * {
    font-size: 1em;
    line-height: 40px;
    letter-spacing: 0.01em;
  }
  & input {
    border: 1px solid #b9b6bc;
    border-right: none;
    width: 56%;
    border-radius: 4px 0 0 4px;
    color: #817673;
    text-align: left;
    padding: 0px 20px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  & button {
    border: none;
    width: 44%;
    background: #ff6a61;
    border-radius: 0 4px 4px 0;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const StyledMessage = styled.p`
  padding-bottom: 16px;
`

const ColoredEmail = styled.span`
  color: #ff7572;
`

const StyledErrorMessage = styled.p`
  color: #d40c15;
  padding-top: 4px;
  padding-left: 4px;
  & span {
    padding-left: 4px;
  }
`

const StyledSvgIcon = styled.svg`
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
`

const ErrorIcon = () => (
  <StyledSvgIcon viewBox={'6 6 20 20'}>
    <path
      fill="#d40c15"
      d="M16,7c-4.971,0-9,4.029-9,9s4.029,9,9,9s9-4.029,9-9S20.971,7,16,7z M17,20c0,0.553-0.447,1-1,1c-0.552,0-1-0.447-1-1v-4.5c0-0.552,0.448-1,1-1c0.553,0,1,0.448,1,1V20z M16,13.25c-0.689,0-1.25-0.561-1.25-1.25s0.561-1.25,1.25-1.25s1.25,0.561,1.25,1.25S16.689,13.25,16,13.25z"
    />
  </StyledSvgIcon>)


class EmailInput extends Component {
  state = {
    value: '',
    enabled: true,
    error: '',
  }
  static contextType = SignedUpContext

  handleChange = event => {
    this.setState({ value: event.target.value, enabled: true })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.state.enabled) return false
    if (!this.state.value) return false

    const email = this.state.value
    const { linkOnly } = this.props
    this.setState({ enabled: false, error: '' })
    this.context.signUp({ email, linkOnly }).then(resp => {
      if (resp.ok) {
        console.log('you are signed up!')
      } else {
        resp.json().then(err => {
          console.error(err)
          const msg = err.message || err.error
          if (msg && typeof(msg) == 'string')  this.setState({ error: msg })
        })
      }
    }).catch(err => {
      console.error(err)
      if (err.message && typeof(err.message) == 'string') this.setState({ error: err.message })
    })
  }

  render = () => (
    this.context.isSignedUp ? (
      <StyledMessage>
        Awesome! Welcome. We sent a link to <ColoredEmail>{this.context.email}</ColoredEmail>.
        Enjoy!
      </StyledMessage>
    ) : (
      <FormContainer>
        <Form onSubmit={this.handleSubmit}>
          <input placeholder="Email" type="email" aria-label="Email"
            value={this.state.value}
            onChange={this.handleChange}
          />
          <button disabled={!this.state.enabled}>{this.props.children}</button>
        </Form>
        {this.state.error && (
          <StyledErrorMessage>
            <ErrorIcon />
            <span>{this.state.error}</span>
          </StyledErrorMessage>
        )}
      </FormContainer>
    )
  )
}

export default EmailInput;
