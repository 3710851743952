import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { RightRow, Column, media, H1, P } from "theme";
import { staticSrc, staticSrcSet } from "assets";


const Article = styled(RightRow)`
  margin-bottom: 120px;
  background: url("${staticSrc('glow/Glow-TrainYourAI', 'svg')}")
    bottom right / contain no-repeat;
  ${media.md`
    margin-bottom: 120px;
  `}
`;
const Info = styled(Column)`
  padding-right: 30px;
  ${media.md`
    width: 50%;
  `}
`;
const Title = styled(H1)`
  padding: 30px 0 50px 0;
`;

const Paragraph = styled(P)``;

const Figure = styled(Column)`
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  ${media.md`
    width: auto;
  `}
  ${media.sm`
    & img {
      width: 60%;
      margin-left: auto;
    }
  `}
`;

const Train = () => (
  <Article>
    <Info max="629px">
      <Title>More of a muse. Less of a platform.</Title>
      <Paragraph>
        When we say “designed just for you,” we really mean it. Enjoy having your own fun connection with your Hai—a one-of-a-kind relationship that strengthens over time. The more you use yours—telling it what books, movies, shows and restaurants you love—the more it learns about you, the more precise its recommendations for you become. Kind of like that reliable best friend who knows you almost as well as you know yourself. Only smarter.
      </Paragraph>
    </Info>
    <Figure>
      <Fade right>
        <img
          src={staticSrc('iphone/iPhoneX-TrainYourAI', 'png')}
          srcSet={staticSrcSet('iphone/iPhoneX-TrainYourAI', 'png')}
          alt="Avatar"
          width="100%"
        />
      </Fade>
    </Figure>
  </Article>
);

export default Train;
