import React from "react";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, H1, media } from "theme";
import ArticleList from "components/ArticleList";
import Data from "../data";

const Section = styled(Row)`
  margin-bottom: 100px;
`;

const Article = styled.article``;

const Title = styled(H1)`
  padding: 0 0 50px 0;
`;

const Link = styled(NavLink)`
  width: 100%;
`;

const MainImg = styled.img``;

const Info = styled.div``;

// create a component for banner image to get spacing and absolute position
const BannerImgInner = styled.img`
  position: absolute;
  left: 0px;
  width: 100vw;
  /*
  width: 130%;
  margin: 0 15% 12px -15%;*/
  height: 350px;
  object-fit: cover;
`;

const BannerImgVerticalPlaceHolder = styled.div`
  height: 350px;
`

export const BannerImg = props => (
  <>
    <BannerImgInner {...props} />
    <BannerImgVerticalPlaceHolder />
  </>
)

export const LargeImg = styled.img`
  width: 130%;
  margin: 16px 15% 16px -15%;
  ${media.sm`
    width: 96%;
    margin: 8px 2%;
  `}
`;

export const SmallImg = styled.img`
  width: 80%;
  border-radius: 4px;
  ${media.md`
    width: 64%;
  `}
  ${media.lg`
    width: 64%;
  `}
`;

const BlogArticle = ({ slug }) => {
  const item = Data.articles.find(obj => {
    return obj.slug === slug;
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{item.title}</title>
        <meta name="description" content={item.snippet} />
      </Helmet>
      <Section>
        <Link to={"/blog"}>Back To Blog</Link>
        <Article>
          <MainImg src={item.src} srcSet={item.srcSet} alt={item.snippet} />
          <Title>{item.title}</Title>
          <Info>
            {item.author}, {item.date}
          </Info>
        </Article>
      </Section>
      <Section>
        <Link to={"/blog"}>Back To Blog</Link>
        <ArticleList />
      </Section>
    </React.Fragment>
  );
};

export default BlogArticle;
