import React from "react";
import styled from "styled-components";
import { Row, Column, media, H1, P } from "../../../theme";
import AvatarImage from "../../../components/Avatar";
import EmailInput from "../../../components/EmailInput";
import AppBadges from "../../../components/AppBadges";
import ScrollIcon from "../../../assets/ico_back_med_black_default.svg";

const Article = styled(Row)`
  margin-bottom: 75px;
  margin-right: 30px;
  min-height: calc(100vh - 100px); /* takes full page minus padding of parent */
  align-items: center;
  ${media.md`
    margin-bottom: 100px;
  `}
`;
const Info = styled(Column)`
  ${media.md`
    width: 62%;
  `}
`;
const Title = styled(H1)`
  padding-bottom: 50px;
`;

const Paragraph = styled(P)``;
const Figure = styled(Column)`
  width: 100%;
`;

const Avatar = styled(AvatarImage)`
  width: 100px;
  &.avatar {
    align-self: center;
  }
  ${media.md`
    width: 115px;
  `}
`;

const Scroll = styled(Column)`
  display: none;
  ${media.md`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top:30px;
    margin-bottom: 20px;
  `}
`;

const Hero = () => (
  <Article>
    <Info max={"400px"}>
      <Title>Spot on recommendations. Just for you.</Title>
      <Paragraph>
        The world’s most reliable recommendations app learns which movies,
        shows, books, restaurants and songs you like—and then recommends new
        ones you’ll love. Say hello to Hai!
      </Paragraph>
      <EmailInput>Sign Me Up</EmailInput>
      <AppBadges />
    </Info>
    <Figure width={"38%"}>
      <Avatar className="avatar" />
    </Figure>
    <Scroll>
      <img src={ScrollIcon} alt="scroll" />
    </Scroll>
  </Article>
);

export default Hero;
