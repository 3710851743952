import React from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";
import { media, Row, Column, H1, H2 } from "../../theme";
import PressMediaNav from "../../components/PressMediaNav";
import DownloadIcon from "../../assets/dl_screens_icon.svg";
import Discovery1 from "../../assets/Discovery_Screen.png";
import Discovery2 from "../../assets/Discovery2_Screen.png";
import Train from "../../assets/Train_Screen.png";
import Ask from "../../assets/Ask_Me_Screen.png";
import HaiLogo from "../../assets/Hai_Logo.svg";
import DontDo from "../../assets/Dont_Do.png";

const Section = styled(Row)`
  margin-bottom: 100px;
`;
const Article = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 65px;
  ${media.md`
    width: 100%;
  `}
`;

const Title = styled(H1)`
  line-height: 32px;
  font-size: 22px;
  margin-bottom: 30px;
`;

const Button = styled.a`
  position: relative;
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  background: #2d1943;
  border-radius: 4px;
  padding: 10px 0;
  margin-bottom: 50px;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    margin: 6px 0 0 12px;
    background: url(${DownloadIcon}) center / contain no-repeat;
  }
`;

const Info = styled(Column)`
  ${media.md`
    width: 100%;
    padding-right: 50%;
  `}
`;

const Screenshot = styled.div`
  width: calc(50%);
  text-align: center;
  margin: 0 0 40px 0px;
  & img {
    width: 160px;
  }
  ${media.md`
    width: calc(25%);
  `}
`;

const FootInfo = styled.div`
  width: 100%;
  text-align: right;
  line-height: 16px;
  font-size: 12px;
  color: #817673;
  border-top: 1px solid #b9b6bc;
  padding: 10px 0;
  & a {
    text-decoration: none;
    color: #2d1943;
  }
`;

const SubTitle = styled(H2)(
  props => css`
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 0.02em;
    padding-bottom: 20px;
    color: ${props.color};
  `
);

const Logo = styled.div(
  props => css`
    width: 100%;
    text-align: center;
    padding: 72px 0;
    border: ${props.border};
    background: ${props.background};
    & img {
      width: 82px;
    }
    ${media.md`
    width: 50%;
  `}
  `
);

const InfoList = styled(Column)`
  & > ul {
    font-weight: bold;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #4b3731;
  }
  & > ul li {
    padding: 0 0 20px 0;
    list-style: disc inside none;
    display: list-item;
  }
  ${media.lg`
    width: 30%;
  `}
`;

const Figure = styled(Column)`
  text-align: center;
  margin: 50px 0;
  & img {
    width: 220px;
  }
  ${media.lg`
    width: 30%;
  `}
`;

const MediaPage = () => (
  <Section>
    <Helmet>
      <title>Hai | Media</title>
      <meta name="description" content="Helmet application" />
    </Helmet>
    <PressMediaNav />
    <Article>
      <Info>
        <Title>High Resolution App Screenshots</Title>
        <Button href="https://static.h.ai/assets/media/hai_screenshots.zip" download>
          Download Screenshots
        </Button>
      </Info>
      <Screenshot>
        <a href={Discovery1} download>
          <img src={Discovery1} alt="Discovery 1" />
        </a>
      </Screenshot>
      <Screenshot>
        <a href={Discovery2} download>
          <img src={Discovery2} alt="Discovery 2" />
        </a>
      </Screenshot>
      <Screenshot>
        <a href={Train} download>
          <img src={Train} alt="Train" />
        </a>
      </Screenshot>
      <Screenshot>
        <a href={Ask} download>
          <img src={Ask} alt="Ask Me" />
        </a>
      </Screenshot>
      <FootInfo>
        Press & Media Requests.
        <br />
        Please email{" "}
        <a href="mailto: press@crossingminds.com">press@crossingminds.com</a>
      </FootInfo>
    </Article>
    <Article>
      <Info>
        <Title>High Resolution Logos</Title>
        <Button href="https://static.h.ai/assets/media/hai_logo.zip" download>
          Download Logos
        </Button>
        <SubTitle>Logos</SubTitle>
      </Info>
      <Logo border={"1px solid #b9b6bc"}>
        <a href={HaiLogo} download>
          <img src={HaiLogo} alt="Logo" />
        </a>
      </Logo>
      <Logo background={"#2D1943"}>
        <a href={HaiLogo} download>
          <img src={HaiLogo} alt="Logo" />
        </a>
      </Logo>
    </Article>
    <Article>
      <InfoList>
        <SubTitle>What not to do with the Logo</SubTitle>
        <SubTitle color={"#c10000"}>Don’t</SubTitle>
        <ul>
          <li>Rotate the logo</li>
          <li>Add any drop shadows</li>
          <li>Outline the logo</li>
          <li>Rearrange the logo</li>
          <li>Change the logo colors</li>
          <li>Stretch the logo</li>
        </ul>
      </InfoList>
      <Figure>
        <img src={DontDo} alt="Don't Do's" />
      </Figure>
    </Article>
  </Section>
);

export default MediaPage;
