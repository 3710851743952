import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Press from "./pages/press";
import Media from "./pages/media";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import NotFound404 from "./pages/not-found";
import Blog from "./pages/blog";
import Article01 from "./pages/blog/articles/Article01";
import Article02 from "./pages/blog/articles/Article02";
import Article03 from "./pages/blog/articles/Article03";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const Routes = () => (
  <React.Fragment>
    <Route component={ScrollToTop} />
    <Switch>
      <Route exact path="/" render={() => <Home />} />
      <Route path="/about" render={() => <About />} />
      <Route path="/terms-of-service" render={() => <Terms />} />
      <Route path="/privacy-policy" render={() => <Privacy />} />
      <Redirect exact from="/press-and-media" to="/press-and-media/press" />
      <Route path="/press-and-media/press" render={() => <Press />} />
      <Route path="/press-and-media/media" render={() => <Media />} />
      <Route exact path="/blog" render={() => <Blog />} />
      <Route
        path="/blog/why-we-need-personalized-recommendations"
        render={props => <Article01 slug={props.match.params.slug} />}
      />
      <Route
        path="/blog/advertising-vs-recommendation"
        render={props => <Article02 slug={props.match.params.slug} />}
      />
      <Route
        path="/blog/hai-world"
        render={props => <Article03 slug={props.match.params.slug} />}
      />
      <Route render={() => <NotFound404 />} />
    </Switch>
  </React.Fragment>
);

export default Routes;
