import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { GlobalStyle } from "theme";
import { staticSrc } from "assets"
import Header from "./Header";
import Footer from "./Footer";

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 150px 0 0 0;
  &:before {
    content: "";
    width: 100%;
    height: 350px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    /* header bg */
    background: url(${staticSrc('background/header-bg_particles', 'svg')})
        center right / contain no-repeat,
      linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 100%),
      linear-gradient(
        160deg,
        #fbc5ab 0%,
        #f3dfaf 15%,
        #c8f8f2 75%,
        #c8f8f2 100%
      );
  }
  /* footer bg */
  &:after {
    content: "";
    width: 100%;
    height: 350px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -10;
    background: url(${staticSrc('background/Background_mesh', 'svg')}")
      bottom center / cover no-repeat;
  }
`;

const Layout = ({ children }) => (
  <React.Fragment>
    <GlobalStyle />
    <Header />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </React.Fragment>
);

export default withRouter(Layout);
