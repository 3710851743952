import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import { getToken, signUp } from './api.js'
import { SignedUpContext } from './context.js'
import Layout from "./components/Layout";
import Routes from "./routes";

class App extends Component {
  signUp = ({ email, ...params }) => new Promise((resolve, reject) => {
    signUp({ email, ...params })
    .then(message => {
      this.setState({ isSignedUp: true, email: email })
      resolve(message)
    })
    .catch(err => reject(err))
  })

  state = {
    isSignedUp: false,
    signUp: this.signUp,
    email: '',
  }

  componentDidMount = () => {
    ReactPixel.init('2128995420529903');
    ReactPixel.pageView();
    getToken().catch(err => console.error(err))
  }

  render = () => (
    <BrowserRouter>
      <SignedUpContext.Provider value={this.state} >
        <Layout>
          <Routes />
        </Layout>
      </SignedUpContext.Provider>
    </BrowserRouter>
  )
}

export default App;
