import React from "react";
import styled from "styled-components";
import { media } from "theme";
import EmailInput from "components/EmailInput";
import AppBadges from "components/AppBadges";
import HaiLogo from "assets/Hai_Logo.svg";
import PopBGImg from "assets/popup-bg.png";
import PopBGImg2x from "assets/popup-bg@2x.png";
import PopBGImg3x from "assets/popup-bg@3x.png";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  width: calc(100vw - 100px);
  height: calc(100vh - 50px);
  margin: 25px 50px;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  & form {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  }
`;

const Background = styled.img`
  width: 320px;
  height: auto;
  margin-left: 20%;
  ${media.lg`
    width: 640px;
  `}
`;
const Logo = styled.img`
  position: absolute;
  width: 10%;
  top: 10%;
  ${media.md`
  width: 8%;
  `}
  ${media.lg`
  width: 6%;
  `}
`;

const Email = styled.div`
  position: absolute;
  max-width: 345px;
  width: 40%;
  top: 30%;
  right: 50%;
`;

const Signup = styled.div`
  position: absolute;
  max-width: 345px;
  width: 40%;
  left: 50%;
  bottom: 10%;
`;
const Title = styled.h1`
  text-align: left;
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #4b3731;
  margin-bottom: 20px;
`;

const GetAppPage = () => (
  <Wrap>
    <Logo src={HaiLogo} alt="hai Logo" />
    <Email>
      <Title>Download the Hai App</Title>
      <EmailInput linkOnly={true}>Email Link</EmailInput>
      <AppBadges />
    </Email>
    <Signup>
      <Title>Or sign up for Hai Web Beta</Title>
      <EmailInput>Sign Up</EmailInput>
    </Signup>
    <Background src={PopBGImg} alt="App image" srcSet={`${PopBGImg2x} 2x, ${PopBGImg3x} 3x`}/>
  </Wrap>
);

export default GetAppPage;
