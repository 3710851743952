import styled from "styled-components";
import { media } from "./index";

export const Row = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
  ${media.xs`
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  `}
  ${media.md`
    margin-left: 65px;
    margin-right: 65px;
    width: calc(100% - 130px);
  `}
  ${media.xl`
    margin-left: 128px;
    margin-right: 128px;
    width: calc(100% - 256px);
  `}
`;

const ShiftedRow = styled(Row)`
  & {
    width: calc(100% - 30px);
    ${media.xs`
      width: calc(100% - 20px);
    `}
    ${media.md`
      width: calc(100% - 65px);
    `}
    ${media.xl`
      width: calc(100% - 128px);
    `}
  }
`

export const LeftRow = styled(ShiftedRow)`
  && {
    margin-left: 0px;
  }
`

export const RightRow = styled(ShiftedRow)`
  && {
    margin-right: 0px;
  }
`

export default Row;
