import React from "react";
import styled from "styled-components";
import AppleBadge from "../assets/Apple_Badge.svg";
import GoogleBadge from "../assets/GooglePlay.png";
import GoogleBadge2x from "../assets/GooglePlay@2x.png";
import GoogleBadge3x from "../assets/GooglePlay@3x.png";

const Badges = styled.div`
  display: flex;
  height: 38px;
  margin-bottom: 25px;
  & > a, span {
    margin-right: 10px;
    & > img {
      height: 100%;
    }
  }
`;

const AppBadges = () => (
  <Badges>
    <a href="https://itunes.apple.com/us/app/h-ai/id1414442334">
      <img src={AppleBadge} alt="Apple Badge" />
    </a>
    <span>
      <img src={GoogleBadge} alt="Google Badge"
           srcSet={`${GoogleBadge2x} 2x, ${GoogleBadge3x} 3x`} />
   </span>
  </Badges>
);

export default AppBadges;
