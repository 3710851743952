import React from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { BlogArticle } from "theme";
import ArticleLayout from "components/ArticleLayout";
import HaiBannerImg from "assets/blog-hai-banner.jpg";
import HaiBannerImg2x from "assets/blog-hai-banner@2x.jpg";
import DoorsImg from "assets/blog-hai-doors.jpg";
import DoorsImg2x from "assets/blog-hai-doors@2x.jpg";
import DoorsImg3x from "assets/blog-hai-doors@3x.jpg";
import PromptImg from "assets/blog-hai-prompt.jpg";
import PromptImg2x from "assets/blog-hai-prompt@2x.jpg";
import PromptImg3x from "assets/blog-hai-prompt@3x.jpg";
import AskHaiImg from "assets/blog-hai-ask.jpg";
import AskHaiImg2x from "assets/blog-hai-ask@2x.jpg";
import AskHaiImg3x from "assets/blog-hai-ask@3x.jpg";
import { BannerImg, SmallImg } from ".";


const Link = styled(NavLink)`
`;

const Article03 = () => (
  <React.Fragment>
    <Helmet>
      <title>Hai | Hai World</title>
      <meta
        name="description"
        content="It shouldn’t take half an hour to choose a movie"
      />
    </Helmet>
    <ArticleLayout>
      <BlogArticle>
        <figure>
          <BannerImg
            src={HaiBannerImg}
            srcSet={`${HaiBannerImg2x} 2x`}
            alt="Hai World Banner"
          />
        </figure>

        <h1>
          “Absorb what is useful, discard what is useless and add what is
          specifically your own” — Bruce Lee
        </h1>

        <p>Crossing Minds, Sep 12, 2018</p>

        <p>
          Have you ever received a book recommendation that changed your life?
          Or discovered an old movie or music album that spoke to you in such a
          perfect way that it left you wondering how something so you was never
          suggested to you before? From a hole-in-the wall restaurant to a new
          podcast you listened to on your road trip; from a refreshing cocktail
          you’d never heard of before to a hike through the woods you never knew
          was there…
        </p>

        <p>
          The quality of our leisure life — the space and time during which
          we’re free to choose the beat drumming our life’s symphony — stems
          from the quality of our discoveries; both of our own self, what we
          enjoy, and of the world providing countless wonderful possibilities.
        </p>

        <p>
          And yet it can feel like a part-time job constantly encountering
          option overload and dealing with the <a href="https://www.ted.com/talks/barry_schwartz_on_the_paradox_of_choice"
              target="_blank" rel="noopener noreferrer">paradox of choice</a>. Although we
          live in a world of abundance, life satisfaction is less about quantity
          and more about quality. So how does one distill the myriad
          possibilities down to the best match for us right now? The answer
          could be: <Link to="/blog/why-we-need-personalized-recommendations">“recommendation engines”</Link>
        </p>

        <figure>
          <SmallImg
            src={DoorsImg}
            srcSet={`${DoorsImg2x} 2x, ${DoorsImg3x} 3x`}
            alt="Three Choices"
          />
        </figure>

        <p>
          Unfortunately, today’s recommendation engines, while ubiquitous in our
          lives, are fundamentally flawed and simply not as good as they should
          be. They’re fragmented, shallow, one-dimensional and biased… with
          limited understanding of the content they recommend, and even less
          understanding of the context in which they function. Adding injustice
          to injury, these algorithms are primarily driven by data that was
          snooped and stolen, tracked and taken without asking (Cf <Link to="/blog/advertising-vs-recommendation"> “Advertising Vs Recommendations”</Link>).
        </p>

        <p>
          To the majority of today’s recommendation engines, users are not
          customers, they’re a commodity. The company-user relationship is
          defined by addictiveness and ad-sales, rather than quality of
          experience and personal wellbeing. Moreover, the algorithms are myopic
          and confined to only a sliver of your true self. They make assumptions
          based on <i>what was</i> rather than <i>what might be</i>, or <i>who you wish to be</i>.
        </p>

        <blockquote>
          Recommendations should help you grow into the person you want to
          become. At the very least, recommendations should meet you where you
          are, in your current mood, and enhance your experiences.
        </blockquote>

        <p>
          So of course, if a recommendation engine is fundamentally designed for
          misalignment with one person’s tastes, how bad can it be when it comes
          to finding the perfect gem for 2, 3 or 6 people? The time that two
          individuals can spend deciding what to watch or what to eat together
          can reach a ridiculous number of minutes or even hours. If it is
          already impossible to truly distill the uniqueness one user, it
          becomes simply absurd pretending to understand multiple sets of
          preferences at once — their contexts and how they align — without
          falling hastily into a series of stereotypes.
        </p>

        <p>
          The passive, exploitative and manipulative relationship with our
          algorithms must evolve as we move into the next generation of
          artificial intelligence. We need an unbiased recommendation engine
          that knows us personally — our taste, interests, and desires. It’s the
          kind of relationship which requires a foundation in trust and
          transparency, with an agile adaptability which delivers what we want,
          when we want it.
        </p>

        <blockquote>
          All this embodies the “why”, why we’ve built Hai: the first pure,
          cross-domain and cross-platform recommendation engine. A personal
          assistant, companion and guide for making the most of your time.
        </blockquote>

        <h3>Hai</h3>

        <p>
          Hai was designed to solve these problems of choice and attention by
          delivering customized recommendations uniquely tailored to the
          individual.
        </p>

        <figure>
          <SmallImg
            src={PromptImg}
            srcSet={`${PromptImg2x} 2x, ${PromptImg3x} 3x`}
            alt="Hai Prompt"
          />
        </figure>

        <p>
          Built on your explicit input and feedback, Hai grows to understand the
          intangible spirit or essence that makes your clock tick. Instead of
          digging through archives or reading reviews online, Hai does all the
          work so you can spend less time searching and more time savoring the
          experience.
        </p>

        <p>
          Rather than getting caught in filter and stereotyped bubbles of biased
          design (such as advertising), Hai helps you expand your horizons; to
          explore adjacent possibilities of your own taste and character. Hai
          finds the hidden gems, weaving through the mainstream and
          counter-stream to find your own special stream of conscious choice.
        </p>

        <p>
          Free and accessible to anyone with access to the Internet, Hai
          democratizes the latest and greatest in Machine Learning. Powered by
          Crossing Minds algorithms, Hai is build upon the latest breakthrough
          in Collaborative Filtering, Deep Content Extraction and Semantic Graph
          Embeddings. Hai grows with, through, and alongside each person’s
          character and development to become a uniquely personal artificial
          intelligence. An AI that really understands you.
        </p>

        <p>
          Platform agnostic, Hai connects with all your favorite platforms for
          seamless synchronization. It’s fluid and frictionless — how technology
          was meant to fit into our lives. Hai is not a content provider, nor an
          advertising platform, its incentives are fully aligned with the user.
        </p>

        <figure>
          <SmallImg
            src={AskHaiImg}
            srcSet={`${AskHaiImg2x} 2x, ${AskHaiImg3x} 3x`}
            alt="Ask Hai"
          />
        </figure>

        <p>
          Everyone gets to develop and train their own Hai, through rating items
          and other means of input and feedback. The more you interact with Hai
          the more it gets to know you, and the more categories and features
          become available for discovery.
        </p>

        <blockquote>
          We believe Hai is a true milestone in the recommendation space, at the
          beginning of an intimate, lifelong companionship between humanity and
          AI.
        </blockquote>

        <p>
          In fact, our long-term vision of Hai is an operating system, much like
          Samantha from the film Her. An intelligent presence that understands
          you and cares for you, and has your best interests at the heart of its
          code.
        </p>

        <p>
          As the savvy Enlightenment figurehead Goethe put it: “Every day we
          should hear at least one little song, read one good poem, see one
          exquisite picture, and, if possible, speak a few sensible words.”
          Thriving, living life as our best selves, becomes a lot easier when we
          are regularly inspired by great art and culture. And that’s our vision
          for life with Hai: self-empowerment through experiences of awe and
          joy, on-demand. Curate your culture, feel the music in everyday life,
          and dance.
        </p>

        <p>Hai is your companion and concierge, a muse.</p>
      </BlogArticle>
    </ArticleLayout>
  </React.Fragment>
);

export default Article03;
