
import TcDisruptImage from "assets/press-tc-disrupt.jpg";
import TcDisruptImage2x from "assets/press-tc-disrupt@2x.jpg";
import TcDisruptImage3x from "assets/press-tc-disrupt@3x.jpg";
import TcArticleImage from "assets/press-tc-article.jpg";
import TcArticleImage2x from "assets/press-tc-article@2x.jpg";
import TcArticleImage3x from "assets/press-tc-article@3x.jpg";
import YahooImage from "assets/press-yahoo.jpg";
import YahooImage2x from "assets/press-yahoo@2x.jpg";
import YahooImage3x from "assets/press-yahoo@3x.jpg";

const Data = {
  seo: "hai | Press",
  page: "Press",
  articles: [
    {
      id: "01",
      href: "https://techcrunch.com/2018/09/06/crossing-minds-would-like-to-recommend-a-few-entertainment-options/",
      title: "Crossing Minds would like to recommend a few entertainment options",
      text:
        "Crossing Minds, which is launching in our Disrupt SF 2018 Battlefield today, is an AI startup that focuses on recommendations. The company’s app, Hai, provides you with a wide range of entertainment recommendations...",
      type: "TechCrunch – Article",
      date: "9.6.2018",
      src: TcArticleImage,
      srcSet: `${TcArticleImage2x} 2x, ${TcArticleImage3x} 3x`
    },
    {
      id: "02",
      href: "https://finance.yahoo.com/video/startup-battlefield-competition-hai-crossing-005210504.html",
      title: "Hai by Crossing Minds | Disrupt SF 2018",
      text:
        "Crossing Minds applies machine learning to enrich the human experience. Using predictive models and taste correlations, we build recommender systems with the ability to adapt to individual and group preferences...",
      type: "Yahoo! Finance – Video",
      date: "9.6.2018",
      src: YahooImage,
      srcSet: `${YahooImage2x} 2x, ${YahooImage3x} 3x`
    },
    {
      id: "03",
      href: "https://techcrunch.com/2018/09/05/announcing-startup-battlefield-at-disrupt-sf-2018/",
      title: "Announcing Startup Battlefield at Disrupt SF 2018",
      text:
        "Every year, TechCrunch chooses an elite cohort of startups to compete in Startup Battlefield — TechCrunch’s premier global startup launch competition. This year’s selection was the most competitive in Startup Battlefield history...",
      type: "TechCrunch – News",
      date: "9.5.2018",
      src: TcDisruptImage,
      srcSet: `${TcDisruptImage2x} 2x, ${TcDisruptImage3x} 3x`
    },
  ]
};

export default Data;
