import React from "react";
import Helmet from "react-helmet";
import { BlogArticle } from "theme";
import ArticleLayout from "components/ArticleLayout";
import PickAColorImg from "assets/blog-personalization-pick-a-color.jpg";
import ChoosePeanutButterImg from "assets/blog-personalization-choosing-peanut-butter.jpg";
import CustomerAlsoBoughtImg from "assets/blog-personalization-customer-also-bought.jpg";
import ZeroFeedbackImg from "assets/blog-personalization-feedback.jpg";
import { LargeImg, SmallImg } from ".";


const Article01 = () => (
  <React.Fragment>
    <Helmet>
      <title>Hai | It shouldn’t take half an hour to choose a movie</title>
      <meta
        name="description"
        content="It shouldn’t take half an hour to choose a movie"
      />
    </Helmet>
    <ArticleLayout>
      <BlogArticle>
        <h1>It shouldn’t take half an hour to choose a movie</h1>
        <h2>Or, why do we need truly personalized media recommendations.</h2>
        <p>Alexandre Robicquet, Mar 12, 2018</p>
        <p>
          Can’t we all relate to this feeling, arriving on the Netflix homepage
          and being overwhelmed by the number of movies to watch and tv-shows to
          binge? Ever indecisive due to all the options we are presented with
          that might be more entertaining, educational, or somehow worthwhile.
          Overwhelming to the point where we would be genuinely stuck for 20
          minutes whilst cycling through the queue trying to decide…
        </p>
        <p>
          This feeling, however, is not exclusive to Netflix. When choosing
          something to read, watch, listen to or do, we have endless
          possibilities and seemingly meager tools or services to sort through
          our options. The ability to connect with what we truly want to
          discover and interact with has become more challenging yet ever more
          important due to the number of options we are constantly facing.
        </p>
        <figure>
          <SmallImg
            src={PickAColorImg}
            alt="Pick A Color"
          />
          <figcaption>Pick A Color–Loryn Brantz Illustration</figcaption>
        </figure>
        <p>
          Surely we can ask friends for suggestions, we can review aggregated
          ratings, and we can browse domain-specific platforms, but too rarely
          can we find the best possible options, uniquely suited to us at a
          specific moment. This is specifically why we need deeply personalized
          recommendation systems.
        </p>
        <h3>Choices And Recommendations</h3>
        <p>
          The number of options we have access to nowadays is exponentially
          growing: millions of songs are available on Spotify, thousands of
          shows and movies streamable online, hundreds of restaurants… Giving
          need to <i>“information filtering systems”</i>, and more specifically <b>recommendations systems</b>.
        </p>
        <p>
          Recommendations are so common that it becomes easy to overlook the
          seamless way recommendation systems have been incorporated into
          practically every device and platform. A recommendation system is
          defined on wikipedia as <i>‘a subclass of an “information filtering
          system” which aims to predict the rating or preference a user would
          give to a specific item’</i>. More than that, recommendation systems are
          <b>“discovery engines”</b>. Tools to assist us by optimizing our personal
          choices, matching our individual preferences across a large body of
          work, seeking out the perfect personalized match.
        </p>
        <p>
          But recommendations systems are not needed solely to find a ‘needle in
          a haystack’. The reality is that, throughout our entire search process
          for the “perfect item” we need recommendations in order to be less
          anxious, to have the luxury not just specifically to find an item, but
          to actually ‘look for’ it. This confusion between ‘finding’ and
          ‘looking for’ can be clarified in the sense that ‘finding’ what we do
          on our own, the final moment when one makes up is mind. We rarely
          received one recommendation spot-on, we receive a curated list of
          suggestions, and then we decide.
        </p>
        <p>
          The reason why I talk about being less anxious or being happier while
          searching for an item is because this abundance of propositions and
          items triggers a real distress. This trigger was called <b>“the Paradox
          of Choice”</b>. First introduced in 2004 in the book The Paradox of
          Choice — Why More Is Less, American psychologist <a href="https://en.wikipedia.org/wiki/Barry_Schwartz_%28psychologist%29" target="blank">Barry Schwartz</a> defends that <b>an overabundance of choices first paralyzed the
          consumers, but more importantly, creates anxiety and frustration.</b>
        </p>
        <figure>
          <LargeImg
            src={ChoosePeanutButterImg}
            alt=" Choosing peanut butter is hard "
          />
          <figcaption>
             Choosing peanut butter is hard–Calvin and Hobbes
          </figcaption>
        </figure>
        <p>
          <i>There are now several books and magazines devoted to what is called
          the “voluntary simplicity” movement. Its core idea is that we have too
          many choices, too many decisions, too little time to do what is really
          important. […] Taking care of our own “wants” and focusing on what we
          “want” to do does not strike me as a solution to the problem of too
          much choice. — <a href="https://en.wikipedia.org/wiki/Barry_Schwartz_%28psychologist%29" target="blank">Barry Schwartz</a></i>
        </p>
        <p>
          This sends us back to our “Netflix Homepage” situation. People are
          often paralyzed by choices and need more individually designed
          recommendations. Another factor is the fear of making a “sub-optimal
          choice”. The FOMO (Fear Of Missing Out) the right items creates in
          addition a deep frustration and un-satisfaction. A concrete
          illustration of these phenomenons is given by those simple metrics:
          the average worldwide Netflix user spent <a href="https://askwonder.com/q/how-much-time-is-wasted-worldwide-on-people-looking-for-movies-to-watch-on-netflix-annually-57bbcd2d1f65962700547278"
            target="blank">21 minutes</a> a day or 126 hours
          a year looking for something to watch.
        </p>
        <p>
          This is why personalized recommender systems are meaningful in the
          first place. By filtering in this abundance of items the ones that
          would be relevant to one user, it allows in the first place to
          eliminate the noise, to reduce the anxiety, to reduce the frustration,
          and to really give us the opportunity to decide among a relevant set
          of propositions.
        </p>
        <h3>Why Recommendation Are Not Personalized</h3>
        <p>
          But if recommendations are presented to me, aren’t they already
          tailored to my unique set of tastes and preferences? Why am I
          insisting on the “personalized” aspect?<br/>
          To answer that, we would need
          to consider a more mathematical approach of recommendations systems.
        </p>
        <h4>Collaborative Filtering</h4>
        <p>
          Typically, the most used mathematical approach for recommenders system
          are “User-Item Matrix Factorization”, or more commonly called
          “Collaborative Filtering” or “Social Filtering”. <b> Collaborative
          filtering</b> filters information by using the tastes of other people. <b>It
          is based on the idea that people who agreed in their evaluations of
          certain items in the past are likely to agree again in the future.</b> For
          example a person who wants to see a movie might ask for
          recommendations from friends. The recommendations of some friends who
          have similar interests are trusted more than recommendations from
          others. This can be summarized by the sentence <i>“people who have the
          same taste as you liked this, so you will like it too”</i>.
        </p>
        <figure>
          <SmallImg
            src={CustomerAlsoBoughtImg}
            alt="Customers Also Bought..."
          />
          <figcaption>
            Customers also bought...–Rina Piccolo
          </figcaption>
        </figure>
        <p>
          But the overall consequence of abusing this approach (like the
          majority of businesses) has more impact than we think. Individuals are
          mathematically represented as a group, and groups as masses. A series
          of clusterization that progressively <b>leads algorithms to deliver
          recommendations based on how similar we are to other users, and not on
          what makes us unique.</b>
        </p>
        <p>
          One compelling illustration of this clusterization is found in the
          case of Netflix, where <i>“more than 80 per cent of the TV shows people
          watch on Netflix are discovered through the platform’s recommendation
          system”</i>. In their specific case, recommendations are the results of
          cohorting users into ~2000 <a href="https://www.wired.co.uk/article/how-do-netflixs-algorithms-work-machine-learning-helps-to-predict-what-viewers-will-like"
            target="blank">different taste groups</a>. In other words, for
          Netflix’s total subscriber base (approximatively 250 million active
          profiles) this would mean that one user is represented similarly to
          250m/2,000 ~125,000 others individuals..
        </p>
        <h4>The Importance Of Context</h4>
        <p>
          Another consideration of our uniqueness is the context. It’s all
          relative, isn’t it? The easiest illustration would be to consider
          yourself, and see how contextual changes affect your decisions and
          desires. For instance, what music do you listen to in the morning and
          what music do you listen to at night? Are they the same, or do time
          and events of the day influence your decision? Mood, weather,
          temperature, sleep, emotions etc... All these factors are key elements
          in understanding and predicting what you might want or need at a
          certain moment. However, <b>these are still never fully considered or
          even acknowledged by businesses and media platforms</b>.
        </p>
        <p>
          <i>We have now become algorithmically represented based on the
          similarities we shared with our neighbor in addition to being deprived
          of our context… Where is the uniqueness in this?</i>
        </p>
        <figure>
          <SmallImg
            src={ZeroFeedbackImg}
            alt="Zero Degree Feedback"
          />
          <figcaption>
            Zero Degree Feedback–Tom Fishburne
          </figcaption>
        </figure>
        <p>
          How can one service claim to find the best movie for me tonight if I
          know there is no way it encompassingly understands who I am, at this
          moment?
        </p>
        <p>
          <b>The need to demand “personalized recommendation” becomes equivalent to
          the need for valuing and acknowledging the uniqueness that we have as
          human beings, individuals.</b> Because without it, vicious cycles arise
          from these interactions, creating problems like “filter bubbles” that
          hinder self-development and exploration. Many recommendation engines
          operate in moral hazard zones through the Principal-Agent Problem:
          concerned with profits and addictiveness, they don’t have user’s
          long-term, best interest at heart. Therefore, by leaving this fight,
          being at peace with the mediocrity of the recommendation platforms are
          delivering to us on a daily basis, we implicitly give our approval. We
          agree that “what you [media services] are creating and presenting to
          me will satisfy my binging addiction for a moment”. The goal of those
          platforms is to create and promote something that will satisfy the
          mass, not ourself. <b>And by not asking for better, by not reclaiming our
          identity, we implicitly agree to be seen as an element of this mass,
          and not as an individual.</b>
        </p>
        <p>
          It is with all those considerations at heart that Dr. Emile Contal and
          myself co-founded <a href="https://crossingminds.com/" taget="_blank">Crossing Minds</a>. We have spent our careers seeking to
          develop contextually-relevant, optimized recommender systems to
          deliver the best possible personalized recommendations for users at
          the most opportune time. Through user-filtering, item-filtering,
          content-filtering, deep learning, and transfer learning, the
          recommendation systems developed by the company seek to surface the
          best recommendations unique to each user, not simply for “users like
          you”. That’s the company promise.
        </p>
        <p>
          We are currently open for sign-ups to our Early Access program for
          hai, our first experience focused on delivering the best
          recommendations for media you’ll love but haven’t discovered yet.
        </p>
        <p>Thank you for reading!</p>
      </BlogArticle>
    </ArticleLayout>
  </React.Fragment>
);

export default Article01;
