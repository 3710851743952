import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

  ${reset}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }

  #root {
    position:relative;
    display: flex;
    flex-flow: column;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: subpixel-antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }

  a, a:active {
    color: #2A1D5E;
    &, a:visited {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  a:visited {
    color: #25263D;
  }

  a:hover {
    text-decoration:none;
  }

  i {
    font-style: italic;
  }

  b {
    font-weight: 700;
  }
`;

export default GlobalStyle;
