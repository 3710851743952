import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { media, Column, H1, P } from "../theme";
import Data from "../pages/blog/data";

const Article = styled(Column)`
  margin-bottom: 40px;
  & a {
    text-decoration: none;
  }
  ${media.md`
    width: calc(50% - 32px);
  `}
  ${media.lg`
    width: calc(33.3333% - 32px);
  `}
`;

const Thumb = styled.img`
  width: 100%;
  height: 210px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const ArticleTitle = styled(H1)`
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding: 10px 0;
  ${media.md`
    height: 70px;
  `}
`;

const Paragraph = styled(P)`
  font-weight: 400;
  ${media.md`
    height: 90px;
  `}
  ${media.lg`
    height: 70px;
  `}
`;

const Author = styled.span`
  line-height: 18px;
  font-size: 14px;
  color: #4b3731;
`;

const ArticleList = () => (
  <React.Fragment>
    {Data.articles.map(item => (
      <Article key={item.id}>
        <NavLink to={`/blog/${item.slug}`}>
          <Thumb src={item.src} srcSet={item.srcSet} />
          <ArticleTitle>{item.title}</ArticleTitle>
          <Paragraph>{item.snippet}</Paragraph>
          <Author>{item.author}</Author>
        </NavLink>
      </Article>
    ))}
  </React.Fragment>
);

export default ArticleList;
