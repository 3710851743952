const Data = {
  seo: "Hai | About",
  page: "About",
  about: {
    title: "We Believe in Dreaming Big.",
    content:
      "<p>And we believe in cultivating the ability to deliver those dreams. We set the highest absolute standards for our work, with compassion for our partners, for the people we hire, and for ourselves.</p><p>We are here because we believe that rather than being hidden in the depths of academia or solely benefitting company profits, the best of Machine Learning should be directly and genuinely applied toward products that create transformational experiences for users and to address the unique challenges associated with the complexity of every individual.</p>"
  },
  team: {
    title: "The Team",
    content:
      "<p>Crossing Minds, the brain behind Hai, was founded by a team of academics with a vision to solve how Artificial Intelligence predicts the uniqueness of individual preferences.</p><p>We have since grown into a diverse team from all over the world, deeply committed to the company's mission to use technology to improve people's everyday lives.</p>",
    image: ""
  }
};

export default Data;
