import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import EmailInput from "./EmailInput";
import AppBadges from "./AppBadges";
import SocialMedia from "./SocialMedia";
import { media } from "../theme";
import CrossingMindsLogo from "../assets/CrossingMinds_Logo.svg";

const FooterWrap = styled.footer`
  display: flex;
  flex-flow: column;
  padding: 0 30px;
  ${media.md`
    flex-flow: row wrap;
    padding: 0 65px;
  `}
  ${media.xl`
    padding: 0 256px;
  `}
`;

const Title = styled.h1`
  width: 100%;
  font-weight: 600;
  line-height: 32px;
  font-size: 22px;
  color: #4b3731;
  margin: 0 0 25px 0;
`;

const Info = styled.div`
  width: 100%;
  margin: 0 0 80px 0;
  ${media.md`
    width: 50%;
  `};
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 35px 0;
  ${media.md`
    width: 50%;
    padding: 0 0 0 35px;
  `};
`;

const linkOrAStyle = `
  width: 50%;
  text-decoration: none;
  color: #2d1943;
  line-height: 18px;
  font-size: 14px;
  margin-bottom: 25px;
  &:hover {
    color: #ff6a61;
  }
`
const Link = styled(NavLink)`${linkOrAStyle}`;
const A = styled.a`${linkOrAStyle}`;

const CrossingMinds = styled.div`
  width: 100%;
  margin: 0 0 65px 0;
  text-align: center;
  & a {
    height: 70px;
    display: inline-block;
  }
  & img {
    height: 100%;
  }
`;

const Footer = () => (
  <FooterWrap>
    <Title>More recommendations. Less options.</Title>
    <Info>
      <EmailInput>Sign Me Up</EmailInput>
      <AppBadges />
      <SocialMedia />
    </Info>
    <Nav>
      <Link exact to="/">
        Home
      </Link>
      <Link to="/blog">Blog</Link>
      <Link to="/about">About</Link>
      <Link to="/press-and-media/press">Press & Media</Link>
      <A href="https://haihelp.zendesk.com/hc/en-us/categories/360001314492-General-FAQ">FAQ</A>
      <Link to="/terms-of-service">Terms of Service</Link>
      <A href="https://haihelp.zendesk.com/hc/en-us">Help & Support</A>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </Nav>
    <CrossingMinds>
      <a
        href="https://www.crossingminds.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={CrossingMindsLogo} alt="Crossing Minds Logo" />
      </a>
    </CrossingMinds>
  </FooterWrap>
);

export default Footer;
