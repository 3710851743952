import React from "react";
import styled from "styled-components";
import Instagram from "../assets/instagram_rc.svg";
import Facebook from "../assets/facebook_rc.svg";
import Twitter from "../assets/twitter_rc.svg";
import Pinterest from "../assets/pinterest_rc.svg";
// import { media } from "../theme";

const SocialMediaNav = styled.nav`
  display: flex;
  flex-flow: row;
`;

const SocialLink = styled.a`
  margin-right: 30px;
  & > img {
    width: 30px;
  }
`;

const SocialMedia = () => (
  <SocialMediaNav>
    <SocialLink
      href="https://www.instagram.com/discoverhai"
      rel="noopener"
      target="_blank"
    >
      <img src={Instagram} alt="Instagram" />
    </SocialLink>
    <SocialLink
      href="https://www.facebook.com/discoverhai"
      rel="noopener"
      target="_blank"
    >
      <img src={Facebook} alt="Facebook" />
    </SocialLink>
    <SocialLink
      href="https://twitter.com/discoverhai"
      rel="noopener"
      target="_blank"
    >
      <img src={Twitter} alt="Twitter" />
    </SocialLink>
    <SocialLink
      href="https://www.pinterest.com/discoverhai"
      rel="noopener"
      target="_blank"
    >
      <img src={Pinterest} alt="Pinterest" />
    </SocialLink>
  </SocialMediaNav>
);

export default SocialMedia;
