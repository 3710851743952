import React from "react";
// import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { Row, Column, media, H2, P } from "../../../theme";
import TechcrunchLogo from "../../../assets/techcrunch-logo.svg";

const Article = styled(Row)`
  text-align: center;
  margin-bottom: 120px;
  ${media.md`
    margin-bottom:120px;
  `}
`;

const SubTitle = styled(H2)`
  padding-bottom: 50px;
`;

const NewsLogo = styled(Column)`
  width: 100%;
  & img {
    align-self: center;
    width: 140px;
  }
  ${media.md`
    width: 100%;
  `}
`;

const NewsQuote = styled(P)`
  width: 100%;
  font-style: italic;
  font-weight: normal;
  line-height: 1.6em;
  font-size: 0.825em;
  letter-spacing: 0.01em;
  text-align: center;
  color: #2d1943;
  margin-bottom: 20px;
`;

const News = () => (
  <Article>
    <SubTitle align={"center"}>In the News</SubTitle>
    <NewsQuote>
      "Crossing Minds, which is launching in our Disrupt SF 2018 Battlefield today, is an AI startup that focuses on recommendations. The company's app, Hai, provides you with a wide range of entertainment recommendations, including books, music, shows, video games and restaurants, based on the data it can gather about you from services like Spotify, Netflix, Hulu and your Xbox." – TechCrunch
    </NewsQuote>
    <NewsLogo>
      <img src={TechcrunchLogo} alt="Techcrunch" />
    </NewsLogo>
  </Article>
);

export default News;
