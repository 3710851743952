import styled from "styled-components";

const H1 = styled.h1`
  width: 100%;
  font-weight: 600;
  line-height: 42px;
  font-size: 32px;
  color: #4b3731;
`;

export default H1;
