import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, Column, media, H1, H2 } from "theme";
import TeamImg from "assets/team.jpg";
import TeamImg2x from "assets/team@2x.jpg";
import TeamImg3x from "assets/team@3x.jpg";
import Data from "./data";

const Article = styled.article`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 80px;
`;

const Title = styled(H1)`
  padding: 0 0 50px 0;
`;

const SubTitle = styled(H2)`
  width: 100%;
  font-weight: 600;
  line-height: 32px;
  font-size: 22px;
  color: #4b3731;
  padding-bottom: 35px;
`;

const Info = styled(Column)`
  width: 100%;
  max-width: 100%;
  width: 100%;
  & p {
    width: 100%;
    max-width: 100%;
    color: #4b3731;
    padding: 0 0 20px 0;
  }
  ${media.md`
    width: 100%;
    &.team-info {
      width: 50%;
      padding: 0 30px 0 0;
    }
  `}
  ${media.lg`
    width: 60%;
    &.team-info{
      width: calc(100% - 391px);
    }
  `}
`;

const Figure = styled(Column)`
  width: 100%;
  & img {
    width: 100%;
    height: auto;
  }
  ${media.md`
    width: 50%;
  `}
  ${media.lg`
    width: 391px;
  `}
`;

const AboutPage = () => (
  <Row>
    <Helmet>
      <title>{Data.seo}</title>
    </Helmet>
    <Title>{Data.page}</Title>
    <Article>
      <SubTitle>{Data.about.title}</SubTitle>
      <Info dangerouslySetInnerHTML={{ __html: Data.about.content }} />
    </Article>
    <Article>
      <SubTitle>{Data.team.title}</SubTitle>
      <Info
        className="team-info"
        dangerouslySetInnerHTML={{ __html: Data.team.content }}
      />
      <Figure>
        <img src={TeamImg} alt="team" srcSet={`${TeamImg2x} 2x, ${TeamImg3x} 3x`}/>
      </Figure>
    </Article>
  </Row>
);

export default AboutPage;
