import PersonalizationThumb from "assets/blog-personalization-customer-also-bought.jpg";
import AdsVsRecoThumb from "assets/blog-ads-vs-reco-thumb.jpg";
import AdsVsRecoThumb2x from "assets/blog-ads-vs-reco-thumb@2x.jpg";
import AdsVsRecoThumb3x from "assets/blog-ads-vs-reco-thumb@3x.jpg";
import HaiThumb from "assets/blog-hai-thumb.jpg";
import HaiThumb2x from "assets/blog-hai-thumb@2x.jpg";
import HaiThumb3x from "assets/blog-hai-thumb@3x.jpg";


const Data = {
  seo: "hai | Blog",
  page: "Blog",
  articles: [
    {
      id: "01",
      slug: "why-we-need-personalized-recommendations",
      title: "It shouldn't take half an hour to choose a movie",
      snippet: "Or, why do we need truly personalized media recommendations.",
      author: "Alexandre Robicquet",
      date: "",
      src: PersonalizationThumb,
    },
    {
      id: "02",
      slug: "advertising-vs-recommendation",
      title: "Advertising vs Recommendation",
      snippet:
        "How targeted advertising is killing recommendation engines at your expense.",
      author: "Emile Contal",
      date: "",
      src: AdsVsRecoThumb,
      srcSet: `${AdsVsRecoThumb2x} 2x, ${AdsVsRecoThumb3x} 3x`
    },
    {
      id: "03",
      slug: "hai-world",
      title: "Hai World",
      snippet:
        "“Absorb what is useful, discard what is useless and add what is specifically your own” — Bruce Lee",
      author: "Crossing Minds Team",
      date: "",
      src: HaiThumb,
      srcSet: `${HaiThumb2x} 2x, ${HaiThumb3x} 3x`
    }
  ]
};

export default Data;
