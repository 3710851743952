import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, H1 } from "../../theme";
import ArticleList from "../../components/ArticleList";
import Data from "./data";

const Section = styled(Row)`
  margin-bottom: 100px;
`;

const Title = styled(H1)`
  padding: 0 0 50px 0;
`;

const BlogPage = () => (
  <Section>
    <Helmet>
      <title>{Data.seo}</title>
      <meta name="description" content="Helmet application" />
    </Helmet>
    <Title>{Data.page}</Title>
    <ArticleList />
  </Section>
);

export default BlogPage;
