import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Nav = styled.nav`
  width: 100%;
  margin: 0 0 50px 0;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  margin-right: 64px;
  font-weight: 600;
  line-height: 42px;
  font-size: 32px;
  text-align: center;
  color: #2d1943;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    color: #ff6a61;
  }
  &.active {
    color: #ff6a61;
  }
`;

const PressMediaNav = () => (
  <Nav>
    <Link to="/press-and-media/press">Press</Link>
    <Link to="/press-and-media/media">Media</Link>
  </Nav>
);

export default PressMediaNav;
