import styled, { css } from "styled-components";
import { media } from "./index";

const Column = styled.div(
  props => css`
    display: flex;
    flex-flow: column;
    overflow: hidden;
    width: 100%;
    ${media.md`
      width: ${props => props.width || "50%"};
    `}
    ${media.lg`
      max-width: ${props.max};
    `}
  `
);

export default Column;
