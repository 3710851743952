import React, { Fragment } from "react";
import Helmet from "react-helmet";
import Hero from "./hero";
import What from "./what";
import Discover from "./discover";
import Train from "./train";
import Share from "./share";
import News from "./news";

const HomePage = () => (
  <Fragment>
    <Helmet>
      <title>Hai | The Spot-On Recommendations App</title>
      <meta name="description" content="Helmet application" />
    </Helmet>
    <Hero />
    <What />
    <Discover />
    <Train />
    <Share />
    <News />
  </Fragment>
);

export default HomePage;
