import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Row } from "../theme";
import ArticleList from "./ArticleList";
import Arrow from "../assets/ico_back_med_black_default.svg";

const Link = styled(NavLink)`
  position: relative;
  display: flex;
  width: 100%;
  text-decoration: none;
  font-weight: normal;
  line-height: 32px;
  font-size: 22px;
  color: #4b3731;
  margin: 0 0 50px 0;
  padding: 0 30px;
  &:before {
    content: "";
    position: absolute;
    background: url(${Arrow}) center no-repeat;
    width: 30px;
    height: 30px;
    left: 0;
    bottom: 0;
    transform: rotate(90deg);
  }
`;

const BlogArticle = ({ children }) => (
  <React.Fragment>
    <Row>
      <Link to={"/blog"}>Back To Blog</Link>
    </Row>
    {children}
    <Row>
      <Link to={"/blog"}>Back To Blog</Link>
    </Row>
    <Row>
      <ArticleList />
    </Row>
  </React.Fragment>
);

export default BlogArticle;
