// import React from "react";
import styled from "styled-components";
import AvatarImage from "../assets/Avatar.png";

const Avatar = styled.img.attrs({
  src: `${AvatarImage}`,
  alt: "Avatar"
})``;

export default Avatar;
