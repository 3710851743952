import { css } from "styled-components";

const sizes = {
  xs: `max-width: ${320 / 16}em`,
  sm: `max-width: ${768 / 16}em`,
  md: `min-width: ${768 / 16}em`,
  lg: `min-width: ${1280 / 16}em`,
  xl: `min-width: ${1920 / 16}em`,
};

const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (${sizes[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});


export default media;
