import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { RightRow, Column, media, H1, H2, P } from "theme";
import EmailInput from "components/EmailInput";
import PlatformIcons from "assets/platform-icons.png";
import PlatformIcons2x from "assets/platform-icons@2x.png";
import PlatformIcons3x from "assets/platform-icons@3x.png";
import { staticSrc, staticSrcSet } from "assets";


const Article = styled(RightRow)`
  margin-bottom: 85px;
  background: url("${staticSrc('glow/Glow-What-is-Hai', 'svg')}")
    bottom right / contain no-repeat;
  ${media.md`
    margin-bottom: 140px;
  `}
`;
const Centered = styled.div`
  margin-right: 30px;
  width: 100%;
`
const Info = styled(Column)`
  padding-right: 30px;
  ${media.md`
    width: 50%;
  `}
`;
const Title = styled(H1)`
  padding: 30px 0 50px 0;
`;
const SubTitle = styled(H2)`
  text-align: left;
  padding-bottom: 50px;
`;

const Paragraph = styled(P)``;

const Figure = styled(Column)`
  position: relative;
  text-align: right;
  max-width: 414px;
  margin-left: auto;
`;

const Posters = styled.div`
  display: flex;
  flex: row nowrap;
  width: 414px;
  height: 194px;
  margin-bottom: 30px;
`;

const Poster = styled.img`
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 16px 16px 32px rgba(0, 0, 0, 0.25);
`;

const Mackbook = styled.img`
  height: 230px;
`;

const Platforms = styled.img`
  position: absolute;
  right: 50px;
  bottom: 50px;
`;

const VideoHWrapper = styled.div`
  max-width: 785px;
  ${media.xl`
    max-width: 981px;
  `}
  margin: auto;
  margin-bottom: 60px;
`
const VideoVWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
  width: 100%;
  & iframe{
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
`

const HAI_YOUTUBE_KEY = 'Gq75RxxmAvk'
const YOUTUBE_PARAMS = {
  autoplay: 0,
  fs: 0,
  loop: 0,
  modestbranding: 1,
  rel: 0,
}
const HAI_YOUTUBE_URL = `https://www.youtube.com/embed/${HAI_YOUTUBE_KEY}/?` +
  Object.entries(YOUTUBE_PARAMS).map(i => `${i[0]}=${i[1]}`).join('&')

const What = () => (
  <Article>
    <Centered>
      <VideoHWrapper>
        <VideoVWrapper>
          <iframe id="ytplayer" type="text/html" width="640" height="360" title="Hai presentation video" src={HAI_YOUTUBE_URL} frameBorder="0"></iframe>
        </VideoVWrapper>
      </VideoHWrapper>
    </Centered>
    <Info max={"629px"}>
      <Title>Reintroducing recommendations.</Title>
      <Paragraph>
      In a world full of customization—doesn’t it seem like there’s always a ton of options piled up in our queues, but still never anything good to watch? That frustrating day has come and gone forever.
      </Paragraph>
      <Paragraph>
      Introducing Hai—a mobile and web app that become your very own recommendations platform. Hai learns what you like so it can recommend new things that you’ll love. Without endless options.
      </Paragraph>
      <EmailInput>Sign Me Up</EmailInput>
    </Info>
    <Figure max={"400px"}>
      <SubTitle>Top Movies for You</SubTitle>
      <Fade right>
        <Posters>
          <Poster
            src={staticSrc("poster/Annihilation")}
            srcSet={staticSrcSet("poster/Annihilation")}
            alt="Annihilation Movie Poster"
          />
          <Poster
            src={staticSrc("poster/her")}
            srcSet={staticSrcSet("poster/her")}
            alt="Her Movie Poster"
          />
          <Poster
            src={staticSrc("poster/Little-miss-sunshine")}
            srcSet={staticSrcSet("poster/Little-miss-sunshine")}
            alt="Liitle Miss Sunshine Movie Poster"
          />
        </Posters>
      </Fade>
      <Fade>
        <Mackbook
          src={staticSrc("macbook/MacBook", "png")}
          srcSet={staticSrcSet("macbook/MacBook", "png")}
          alt="Avatar"
        />
      </Fade>

      <Fade right>
        <Platforms src={PlatformIcons} alt="icons"
         srcSet={`${PlatformIcons2x} 2x, ${PlatformIcons3x} 3x`}/>
      </Fade>
    </Figure>
  </Article>
);

export default What;
